import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { semesterEventActions, studentRecordActions } from '../../actions';

function* getStudentRecord(actions) {
  try {
    const response = yield axios({
      url: `/registration/student-records/fetch-student`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: studentRecordActions.GET_STUDENT_RECORD_SUCCESS,
      data: response.data,
      studentId: actions.params.student,
    });
  } catch (error) {
    yield put({
      type: studentRecordActions.GET_STUDENT_RECORD_ERROR,
      error: error.data,
    });
  }
}

function* updateCurrentStudentProg(actions) {
  const { studentNumber, studentId, studentProgId } = actions;
  try {
    const response = yield axios({
      url: `/students-mgt/students/current-student-programme/${studentId}/${studentProgId}`,
      method: 'PUT',
    });
    yield put({
      type: studentRecordActions.GET_STUDENT_RECORD_REQUEST,
      studentId: studentNumber,
    });
    yield put({ type: 'SHOW_CURRENT_PROG_MODAL', payload: false });
    yield put({
      type: studentRecordActions.UPDATE_CURRENT_STUDENT_PROG_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentRecordActions.UPDATE_CURRENT_STUDENT_PROG_ERROR,
      error: error.data,
    });
  }
}

function* changeVerifyStatus(actions) {
  const { data, studentNumber, studentProgId } = actions;
  try {
    const response = yield axios({
      url: `/students-mgt/students/document-verification/${studentProgId}`,
      method: 'POST',
      data,
    });
    yield put({
      type: studentRecordActions.CHANGE_VERIFICATION_STATUS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: studentRecordActions.GET_STUDENT_RECORD_REQUEST,
      studentId: studentNumber,
    });

    yield put({
      type: studentRecordActions.GET_DOCUMENT_VERIFICATION_STATUS_REQUEST,
      studentProgrammeId: studentProgId,
    });

    // yield put({
    //   type: 'SHOW_MODAL',
    //   payload: false,
    // });
  } catch (error) {
    yield put({
      type: studentRecordActions.CHANGE_VERIFICATION_STATUS_ERROR,
      error: error.data,
    });
  }
}

function* getStudentVerificationDocs(actions) {
  try {
    const { studentProgrammeId } = actions;
    const response = yield axios({
      url: `/students-mgt/students/verified-documents/${studentProgrammeId}`,
      method: 'GET',
    });
    yield put({
      type: studentRecordActions.GET_DOCUMENT_VERIFICATION_STATUS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentRecordActions.GET_DOCUMENT_VERIFICATION_STATUS_ERROR,
      error: error.data,
    });
  }
}

function* setStudentAcademicStatus(actions) {
  try {
    const { studentProgrammeId, studentId } = actions;

    const response = yield axios({
      url: `/registration/student-records/academic-status/${studentProgrammeId}/${studentId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentRecordActions.SET_STUDENT_ACADEMIC_STATUS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_EDIT_USER_MODAL',
      payload: false,
    });
    yield put({
      type: semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST,
      studentId,
      studentProgrammeId,
    });
  } catch (error) {
    yield put({
      type: studentRecordActions.SET_STUDENT_ACADEMIC_STATUS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetStudentRecord() {
  yield takeLatest(
    studentRecordActions.GET_STUDENT_RECORD_REQUEST,
    getStudentRecord
  );
}

function* watchUpdateCurrentStudentProg() {
  yield takeLatest(
    studentRecordActions.UPDATE_CURRENT_STUDENT_PROG_REQUEST,
    updateCurrentStudentProg
  );
}

function* watchChangeVerifyStatus() {
  yield takeLatest(
    studentRecordActions.CHANGE_VERIFICATION_STATUS_REQUEST,
    changeVerifyStatus
  );
}

function* watchSetStudentAcademicStatus() {
  yield takeLatest(
    studentRecordActions.SET_STUDENT_ACADEMIC_STATUS_REQUEST,
    setStudentAcademicStatus
  );
}

function* watchGetStudentVerificationDocs() {
  yield takeLatest(
    studentRecordActions.GET_DOCUMENT_VERIFICATION_STATUS_REQUEST,
    getStudentVerificationDocs
  );
}

export default [
  fork(watchGetStudentRecord),
  fork(watchChangeVerifyStatus),
  fork(watchSetStudentAcademicStatus),
  fork(watchUpdateCurrentStudentProg),
  fork(watchGetStudentVerificationDocs),
];
