import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { timetablePolicyActions } from '../../actions';

function* getTeachingLoadPolicies(actions) {
  try {
    const response = yield axios({
      url: 'institution-policy-mgt/timetable-policies/teaching-load-policy',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: timetablePolicyActions.GET_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.GET_TEACHING_LOAD_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* getExtraTeachingLoadPolicies(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/timetable-policies/extra-teaching-load-costing-policy',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* createTeachingLoadPolicy(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/timetable-policies/teaching-load-policy',
      method: 'POST',
      data: actions.data,
    });

    yield put({
      type: timetablePolicyActions.CREATE_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SET_SELECTED_TIMETABLE_POLICY',
      data: {},
    });
    yield put({
      type: timetablePolicyActions.GET_TEACHING_LOAD_POLICY_REQUEST,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.CREATE_TEACHING_LOAD_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* createExtraTeachingLoadPolicy(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/timetable-policies/extra-teaching-load-costing-policy',
      method: 'POST',
      data: actions.data,
    });

    yield put({
      type: timetablePolicyActions.CREATE_EXTRA_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SET_SELECTED_TIMETABLE_POLICY',
      data: {},
    });
    yield put({
      type: timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* createFacultyExtraTeachingLoadPolicy(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/timetable-policies/faculty-extra-teaching-load-costing-policy',
      method: 'POST',
      data: actions.data,
    });

    yield put({
      type: timetablePolicyActions.CREATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SET_SELECTED_TIMETABLE_POLICY',
      data: {},
    });
    yield put({
      type: timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.CREATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* createDepartmentExtraTeachingLoadPolicy(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/timetable-policies/department-extra-teaching-load-costing-policy',
      method: 'POST',
      data: actions.data,
    });

    yield put({
      type: timetablePolicyActions.CREATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SET_SELECTED_TIMETABLE_POLICY',
      data: {},
    });
    yield put({
      type: timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.CREATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* createProgrammeExtraTeachingLoadPolicy(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/timetable-policies/programme-extra-teaching-load-costing-policy',
      method: 'POST',
      data: actions.data,
    });

    yield put({
      type: timetablePolicyActions.CREATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SET_SELECTED_TIMETABLE_POLICY',
      data: {},
    });
    yield put({
      type: timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.CREATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteTeachingLoadPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/timetable-policies/teaching-load-policy/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: timetablePolicyActions.DELETE_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SET_SELECTED_TIMETABLE_POLICY',
      data: {},
    });
    yield put({
      type: timetablePolicyActions.GET_TEACHING_LOAD_POLICY_REQUEST,
    });
    yield put({
      type: 'SHOW_TIMETABLE_POLICY_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.DELETE_TEACHING_LOAD_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteExtraTeachingLoadPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/timetable-policies/extra-teaching-load-costing-policy/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: timetablePolicyActions.DELETE_EXTRA_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    });
    yield put({
      type: 'SET_SELECTED_TIMETABLE_POLICY',
      data: {},
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.DELETE_EXTRA_TEACHING_LOAD_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteFacultyExtraTeachingLoadPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/timetable-policies/faculty-extra-teaching-load-costing-policy/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: timetablePolicyActions.DELETE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    });
    yield put({
      type: 'SET_SELECTED_TIMETABLE_POLICY',
      data: {},
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.DELETE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
      error: error.data,
    });
  }
}

function* deleteDepartmentExtraTeachingLoadPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/timetable-policies/department-extra-teaching-load-costing-policy/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: timetablePolicyActions.DELETE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    });
    yield put({
      type: 'SET_SELECTED_TIMETABLE_POLICY',
      data: {},
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.DELETE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteProgrammeExtraTeachingLoadPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/timetable-policies/programme-extra-teaching-load-costing-policy/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: timetablePolicyActions.DELETE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    });
    yield put({
      type: 'SET_SELECTED_TIMETABLE_POLICY',
      data: {},
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.DELETE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateTimetablePolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/timetable-policies/teaching-load-policy/${actions.policyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: timetablePolicyActions.UPDATE_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: timetablePolicyActions.GET_TEACHING_LOAD_POLICY_REQUEST,
    });
    yield put({
      type: 'SET_SELECTED_TIMETABLE_POLICY',
      data: {},
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.UPDATE_TEACHING_LOAD_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateExtraTeachingLoadPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/timetable-policies/extra-teaching-load-costing-policy/${actions.policyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: timetablePolicyActions.UPDATE_EXTRA_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SET_SELECTED_TIMETABLE_POLICY',
      data: {},
    });
    yield put({
      type: timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.UPDATE_EXTRA_TEACHING_LOAD_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateFacultyExtraTeachingLoadPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/timetable-policies/faculty-extra-teaching-load-costing-policy/${actions.policyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: timetablePolicyActions.UPDATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SET_SELECTED_TIMETABLE_POLICY',
      data: {},
    });
    yield put({
      type: timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.UPDATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateDepartmentExtraTeachingLoadPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/timetable-policies/department-extra-teaching-load-costing-policy/${actions.policyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: timetablePolicyActions.UPDATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SET_SELECTED_TIMETABLE_POLICY',
      data: {},
    });
    yield put({
      type: timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.UPDATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateProgrammeExtraTeachingLoadPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/timetable-policies/programme-extra-teaching-load-costing-policy/${actions.policyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: timetablePolicyActions.UPDATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SET_SELECTED_TIMETABLE_POLICY',
      data: {},
    });
    yield put({
      type: timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.UPDATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetTeachingLoadPolicies() {
  yield takeLatest(
    timetablePolicyActions.GET_TEACHING_LOAD_POLICY_REQUEST,
    getTeachingLoadPolicies
  );
}

function* watchGetExtraTeachingLoadPolicies() {
  yield takeLatest(
    timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    getExtraTeachingLoadPolicies
  );
}

function* watchCreateTeachingLoadPolicy() {
  yield takeLatest(
    timetablePolicyActions.CREATE_TEACHING_LOAD_POLICY_REQUEST,
    createTeachingLoadPolicy
  );
}

function* watchCreateExtraTeachingLoadPolicy() {
  yield takeLatest(
    timetablePolicyActions.CREATE_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    createExtraTeachingLoadPolicy
  );
}

function* watchDeleteTeachingLoadPolicy() {
  yield takeLatest(
    timetablePolicyActions.DELETE_TEACHING_LOAD_POLICY_REQUEST,
    deleteTeachingLoadPolicy
  );
}

function* watchDeleteExtraTeachingLoadPolicy() {
  yield takeLatest(
    timetablePolicyActions.DELETE_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    deleteExtraTeachingLoadPolicy
  );
}

function* watchUpdateTeachingLoadPolicy() {
  yield takeLatest(
    timetablePolicyActions.UPDATE_TEACHING_LOAD_POLICY_REQUEST,
    updateTimetablePolicy
  );
}

function* watchUpdateExtraTeachingLoadPolicy() {
  yield takeLatest(
    timetablePolicyActions.UPDATE_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    updateExtraTeachingLoadPolicy
  );
}

function* watchCreateFacultyExtraTeachingLoadPolicy() {
  yield takeLatest(
    timetablePolicyActions.CREATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    createFacultyExtraTeachingLoadPolicy
  );
}

function* watchCreateDepartmentExtraTeachingLoadPolicy() {
  yield takeLatest(
    timetablePolicyActions.CREATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    createDepartmentExtraTeachingLoadPolicy
  );
}

function* watchCreateProgrammeExtraTeachingLoadPolicy() {
  yield takeLatest(
    timetablePolicyActions.CREATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    createProgrammeExtraTeachingLoadPolicy
  );
}

function* watchDeleteFacultyExtraTeachingLoadPolicy() {
  yield takeLatest(
    timetablePolicyActions.DELETE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    deleteFacultyExtraTeachingLoadPolicy
  );
}

function* watchDeleteDepartmentExtraTeachingLoadPolicy() {
  yield takeLatest(
    timetablePolicyActions.DELETE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    deleteDepartmentExtraTeachingLoadPolicy
  );
}

function* watchDeleteProgrammeExtraTeachingLoadPolicy() {
  yield takeLatest(
    timetablePolicyActions.DELETE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    deleteProgrammeExtraTeachingLoadPolicy
  );
}

function* watchUpdateFacultyExtraTeachingLoadPolicy() {
  yield takeLatest(
    timetablePolicyActions.UPDATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    updateFacultyExtraTeachingLoadPolicy
  );
}

function* watchUpdateDepartmentExtraTeachingLoadPolicy() {
  yield takeLatest(
    timetablePolicyActions.UPDATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    updateDepartmentExtraTeachingLoadPolicy
  );
}

function* watchUpdateProgrammeExtraTeachingLoadPolicy() {
  yield takeLatest(
    timetablePolicyActions.UPDATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    updateProgrammeExtraTeachingLoadPolicy
  );
}

export default [
  fork(watchGetTeachingLoadPolicies),
  fork(watchCreateTeachingLoadPolicy),
  fork(watchDeleteTeachingLoadPolicy),
  fork(watchUpdateTeachingLoadPolicy),
  fork(watchGetExtraTeachingLoadPolicies),
  fork(watchCreateExtraTeachingLoadPolicy),
  fork(watchDeleteExtraTeachingLoadPolicy),
  fork(watchUpdateExtraTeachingLoadPolicy),
  fork(watchCreateFacultyExtraTeachingLoadPolicy),
  fork(watchCreateDepartmentExtraTeachingLoadPolicy),
  fork(watchCreateProgrammeExtraTeachingLoadPolicy),
  fork(watchDeleteFacultyExtraTeachingLoadPolicy),
  fork(watchDeleteDepartmentExtraTeachingLoadPolicy),
  fork(watchDeleteProgrammeExtraTeachingLoadPolicy),
  fork(watchUpdateFacultyExtraTeachingLoadPolicy),
  fork(watchUpdateDepartmentExtraTeachingLoadPolicy),
  fork(watchUpdateProgrammeExtraTeachingLoadPolicy),
];
