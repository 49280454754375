import update from 'immutability-helper';
import { sponsorActions } from '../../actions';
import initialState from '../../initialState';

const sponsors = (state = initialState.sponsors, actions) => {
  switch (actions.type) {
    case sponsorActions.GET_SPONSORS_REQUEST:
      return {
        ...state,
        getSponsorsError: {},
        gettingSponsors: true,
      };
    case sponsorActions.GET_SPONSORS_SUCCESS:
      return {
        ...state,
        gettingSponsors: false,
        sponsors: actions.data,
      };
    case sponsorActions.GET_SPONSORS_ERROR:
      return {
        ...state,
        getSponsorError: actions.error,
        gettingSponsors: false,
      };

    case sponsorActions.GET_SPONSOR_BY_CONTEXT_REQUEST:
      return {
        ...state,
        getSponsorByContextError: {},
        gettingSponsorByContext: true,
      };
    case sponsorActions.GET_SPONSOR_BY_CONTEXT_SUCCESS: {
      let { sponsorsByContext } = state;
      const findIndex = sponsorsByContext.findIndex(
        (context) =>
          context.sponsor_id === actions.context.sponsor_id &&
          context.academic_year === actions.context.academic_year &&
          context.semester === actions.context.semester
      );

      if (findIndex === -1) {
        sponsorsByContext = [
          ...sponsorsByContext,
          { ...actions.data, ...actions.context },
        ];
      } else {
        sponsorsByContext[findIndex] = { ...actions.data, ...actions.context };
      }
      return {
        ...state,
        gettingSponsorByContext: false,
        sponsorsByContext,
      };
    }
    case sponsorActions.GET_SPONSOR_BY_CONTEXT_ERROR:
      return {
        ...state,
        getSponsorByContextError: actions.error,
        gettingSponsorByContext: false,
      };

    case sponsorActions.CREATE_SPONSOR_REQUEST:
      return {
        ...state,
        createSponsorError: {},
        creatingSponsor: true,
      };
    case sponsorActions.CREATE_SPONSOR_SUCCESS:
      return {
        ...state,
        creatingSponsor: false,
        createdSponsor: actions.data,
      };
    case sponsorActions.CREATE_SPONSOR_ERROR:
      return {
        ...state,
        createSponsorError: actions.error,
        creatingSponsor: false,
      };

    case sponsorActions.GENERATE_SPONSOR_INVOICE_REQUEST:
      return {
        ...state,
        generateSponsorInvoiceError: {},
        generatingSponsorInvoice: true,
      };
    case sponsorActions.GENERATE_SPONSOR_INVOICE_SUCCESS:
      return {
        ...state,
        generatingSponsorInvoice: false,
        generatedSponsorInvoice: actions.data,
      };
    case sponsorActions.GENERATE_SPONSOR_INVOICE_ERROR:
      return {
        ...state,
        generateSponsorInvoiceError: actions.error,
        generatingSponsorInvoice: false,
      };

    case sponsorActions.RE_GENERATE_SPONSOR_PRN_REQUEST:
      return {
        ...state,
        reGeneratePRNError: {},
        reGeneratingPRN: true,
      };
    case sponsorActions.RE_GENERATE_SPONSOR_PRN_SUCCESS:
      return {
        ...state,
        reGeneratingPRN: false,
        reGeneratedPRN: actions.data,
      };
    case sponsorActions.RE_GENERATE_SPONSOR_PRN_ERROR:
      return {
        ...state,
        reGeneratePRNError: actions.error,
        reGeneratingPRN: false,
      };

    case sponsorActions.GET_SPONSOR_INVOICES_REQUEST:
      return {
        ...state,
        getSponsorInvoicesError: {},
        gettingSponsorInvoices: true,
      };
    case sponsorActions.GET_SPONSOR_INVOICES_SUCCESS: {
      let { sponsorInvoices } = state;
      const findSponsorIndex = sponsorInvoices.findIndex(
        (invoice) => invoice.sponsorId === actions.sponsorId
      );

      if (findSponsorIndex !== -1) {
        sponsorInvoices[findSponsorIndex] = {
          sponsorId: actions.sponsorId,
          invoices: actions.data,
        };
      } else {
        sponsorInvoices = [
          ...sponsorInvoices,
          {
            sponsorId: actions.sponsorId,
            invoices: actions.data,
          },
        ];
      }
      return {
        ...state,
        gettingSponsorInvoices: false,
        sponsorInvoices,
      };
    }
    case sponsorActions.GET_SPONSOR_INVOICES_ERROR:
      return {
        ...state,
        getSponsorInvoiceError: actions.error,
        gettingSponsorInvoices: false,
      };

    case sponsorActions.GET_SPONSOR_PAYMENTS_REQUEST:
      return {
        ...state,
        getSponsorPaymentsError: {},
        gettingSponsorPayments: true,
      };
    case sponsorActions.GET_SPONSOR_PAYMENTS_SUCCESS:
      return {
        ...state,
        gettingSponsorPayments: false,
        sponsorPayments: actions.data,
      };
    case sponsorActions.GET_SPONSOR_PAYMENTS_ERROR:
      return {
        ...state,
        getSponsorPaymentError: actions.error,
        gettingSponsorPayments: false,
      };

    case sponsorActions.GET_SPONSOR_ALLOCATIONS_REQUEST:
      return {
        ...state,
        getSponsorAllocationError: {},
        gettingSponsorAllocation: true,
      };
    case sponsorActions.GET_SPONSOR_ALLOCATIONS_SUCCESS: {
      const { data, allocationId } = actions;
      const { allSponsorAllocations } = state;

      let newState = state;
      const sponsorAllocationData = {
        allocationId,
        sponsorAllocations: data,
      };
      const foundIndex = allSponsorAllocations.findIndex(
        (allocation) => allocation.allocationId === allocationId
      );
      if (foundIndex === -1) {
        newState = update(newState, {
          allSponsorAllocations: { $push: [sponsorAllocationData] },
        });
      } else {
        newState = update(newState, {
          allSponsorAllocations: {
            [foundIndex]: { $set: sponsorAllocationData },
          },
        });
      }

      return { ...newState, gettingSponsorAllocation: false };
    }
    case sponsorActions.GET_SPONSOR_ALLOCATIONS_ERROR:
      return {
        ...state,
        getSponsorAllocationError: actions.error,
        gettingSponsorAllocation: false,
      };

    case sponsorActions.DEALLOCATE_SPONSOR_PAYMENTS_REQUEST:
      return {
        ...state,
        deAllocateSponsorPaymentsError: {},
        deAllocatingSponsorPayments: true,
      };
    case sponsorActions.DEALLOCATE_SPONSOR_PAYMENTS_SUCCESS:
      return {
        ...state,
        deAllocatingSponsorPayments: false,
        deAllocateSponsorPaymentsSuccess: actions.data,
      };
    case sponsorActions.DEALLOCATE_SPONSOR_PAYMENTS_ERROR:
      return {
        ...state,
        deAllocateSponsorPaymentsError: actions.error,
        deAllocatingSponsorPayments: false,
      };

    case sponsorActions.GET_SPONSOR_STUDENTS_REQUEST:
      return {
        ...state,
        getSponsorStudentsError: {},
        gettingSponsorStudents: true,
      };
    case sponsorActions.GET_SPONSOR_STUDENTS_SUCCESS:
      return {
        ...state,
        gettingSponsorStudents: false,
        sponsorStudents: actions.data,
      };
    case sponsorActions.GET_SPONSOR_STUDENTS_ERROR:
      return {
        ...state,
        getSponsorStudentError: actions.error,
        gettingSponsorStudents: false,
      };

    case sponsorActions.UPDATE_SPONSOR_REQUEST:
      return {
        ...state,
        updateSponsorError: {},
        updatingSponsor: true,
      };
    case sponsorActions.UPDATE_SPONSOR_SUCCESS:
      return {
        ...state,
        updatingSponsor: false,
        updatedSponsor: actions.data,
      };
    case sponsorActions.UPDATE_SPONSOR_ERROR:
      return {
        ...state,
        updateSponsorError: actions.error,
        updatingSponsor: false,
      };

    case sponsorActions.DELETE_SPONSOR_REQUEST:
      return {
        ...state,
        deleteSponsorError: {},
        deletingSponsor: true,
        deletedSponsor: {},
      };
    case sponsorActions.DELETE_SPONSOR_SUCCESS:
      return {
        ...state,
        deletingSponsor: false,
        deletedSponsor: actions.data,
      };
    case sponsorActions.DELETE_SPONSOR_ERROR:
      return {
        ...state,
        deleteSponsorError: actions.error,
        deletingSponsor: false,
      };

    case sponsorActions.DOWNLOAD_SPONSOR_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateSuccess: {},
        downloadTemplateError: {},
      };
    case sponsorActions.DOWNLOAD_SPONSOR_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };
    case sponsorActions.DOWNLOAD_SPONSOR_TEMPLATE_ERROR:
      return {
        ...state,
        downloadTemplateError: actions.error,
        downloadingTemplate: false,
      };

    case sponsorActions.UPLOAD_SPONSOR_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateSuccess: {},
        uploadTemplateError: {},
      };
    case sponsorActions.UPLOAD_SPONSOR_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateSuccess: actions.data,
      };
    case sponsorActions.UPLOAD_SPONSOR_TEMPLATE_ERROR:
      return {
        ...state,
        uploadTemplateError: actions.error,
        uploadingTemplate: false,
      };

    case sponsorActions.DOWNLOAD_SPONSOR_TRANSACTIONS_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateSuccess: {},
        downloadTemplateError: {},
      };
    case sponsorActions.DOWNLOAD_SPONSOR_TRANSACTIONS_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };
    case sponsorActions.DOWNLOAD_SPONSOR_TRANSACTIONS_TEMPLATE_ERROR:
      return {
        ...state,
        downloadTemplateError: actions.error,
        downloadingTemplate: false,
      };

    case sponsorActions.UPLOAD_SPONSOR_TRANSACTIONS_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateSuccess: {},
        uploadTemplateError: {},
      };
    case sponsorActions.UPLOAD_SPONSOR_TRANSACTIONS_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateSuccess: actions.data,
      };
    case sponsorActions.UPLOAD_SPONSOR_TRANSACTIONS_TEMPLATE_ERROR:
      return {
        ...state,
        uploadTemplateError: actions.error,
        uploadingTemplate: false,
      };

    case sponsorActions.ALLOCATE_SPONSOR_FUNDS_REQUEST:
      return {
        ...state,
        allocatingFunds: true,
        allocateFundsError: {},
      };
    case sponsorActions.ALLOCATE_SPONSOR_FUNDS_SUCCESS:
      return {
        ...state,
        allocatingFunds: false,
        allocateFundsSuccess: actions.data,
      };
    case sponsorActions.ALLOCATE_SPONSOR_FUNDS_ERROR:
      return {
        ...state,
        allocateFundsError: actions.error,
        allocatingFunds: false,
      };

    case sponsorActions.SET_SELECTED_SPONSOR:
      return {
        ...state,
        selectedSponsor: actions.context,
      };

    case sponsorActions.SET_SELECTED_CONTEXT:
      return {
        ...state,
        selectedContext: actions.context,
      };

    case sponsorActions.SHOW_SPONSOR_MODAL:
      return {
        ...state,
        showSponsorModal: actions.payload,
      };

    case sponsorActions.SET_SPONSOR_INVOICE_TO_ALLOCATE:
      return {
        ...state,
        sponsorInvoiceToAllocate: actions.data,
      };

    case sponsorActions.SET_SPONSOR_CURRENT_MENU:
      return {
        ...state,
        sponsorCurrentMenu: actions.payload,
      };

    case sponsorActions.SET_CURRENT_SPONSOR_ALLOCATION:
      return {
        ...state,
        selectedSponsorAllocation: actions.payload,
      };

    default:
      return state;
  }
};

export default sponsors;
