const studentRegistration = {
  REGISTER_STUDENT_REQUEST: 'REGISTER_STUDENT_REQUEST',
  REGISTER_STUDENT_SUCCESS: 'REGISTER_STUDENT_SUCCESS',
  REGISTER_STUDENT_ERROR: 'REGISTER_STUDENT_ERROR',

  REGISTER_STUDENT_FULLY_REQUEST: 'REGISTER_STUDENT_FULLY_REQUEST',
  REGISTER_STUDENT_FULLY_SUCCESS: 'REGISTER_STUDENT_FULLY_SUCCESS',
  REGISTER_STUDENT_FULLY_ERROR: 'REGISTER_STUDENT_FULLY_ERROR',

  UPDATE_STUDENT_REGISTRATION_REQUEST: 'UPDATE_STUDENT_REGISTRATION_REQUEST',
  UPDATE_STUDENT_REGISTRATION_SUCCESS: 'UPDATE_STUDENT_REGISTRATION_SUCCESS',
  UPDATE_STUDENT_REGISTRATION_ERROR: 'UPDATE_STUDENT_REGISTRATION_ERROR',

  DE_REGISTER_STUDENT_REQUEST: 'DE_REGISTER_STUDENT_REQUEST',
  DE_REGISTER_STUDENT_SUCCESS: 'DE_REGISTER_STUDENT_SUCCESS',
  DE_REGISTER_STUDENT_ERROR: 'DE_REGISTER_STUDENT_ERROR',

  ADD_LATE_REGISTRATION_REQUEST: 'ADD_LATE_REGISTRATION_REQUEST',
  ADD_LATE_REGISTRATION_SUCCESS: 'ADD_LATE_REGISTRATION_SUCCESS',
  ADD_LATE_REGISTRATION_ERROR: 'ADD_LATE_REGISTRATION_ERROR',

  GET_REGISTRATION_HISTORY_REQUEST: 'GET_REGISTRATION_HISTORY_REQUEST',
  GET_REGISTRATION_HISTORY_SUCCESS: 'GET_REGISTRATION_HISTORY_SUCCESS',
  GET_REGISTRATION_HISTORY_ERROR: 'GET_REGISTRATION_HISTORY_ERROR',

  GET_PREVIOUS_REGISTRATION_REQUEST: 'GET_PREVIOUS_REGISTRATION_REQUEST',
  GET_PREVIOUS_REGISTRATION_SUCCESS: 'GET_PREVIOUS_REGISTRATION_SUCCESS',
  GET_PREVIOUS_REGISTRATION_ERROR: 'GET_PREVIOUS_REGISTRATION_ERROR',

  BILL_PREVIOUS_BALANCES_REQUEST: 'BILL_PREVIOUS_BALANCES_REQUEST',
  BILL_PREVIOUS_BALANCES_SUCCESS: 'BILL_PREVIOUS_BALANCES_SUCCESS',
  BILL_PREVIOUS_BALANCES_ERROR: 'BILL_PREVIOUS_BALANCES_ERROR',

  GET_MIGRATED_ENROLLMENT_BALANCES_REQUEST:
    'GET_MIGRATED_ENROLLMENT_BALANCES_REQUEST',
  GET_MIGRATED_ENROLLMENT_BALANCES_SUCCESS:
    'GET_MIGRATED_ENROLLMENT_BALANCES_SUCCESS',
  GET_MIGRATED_ENROLLMENT_BALANCES_ERROR:
    'GET_MIGRATED_ENROLLMENT_BALANCES_ERROR',

  GET_ADMINISTRATIVE_REGISTRATION_REQUEST:
    'GET_ADMINISTRATIVE_REGISTRATION_REQUEST',
  GET_ADMINISTRATIVE_REGISTRATION_SUCCESS:
    'GET_ADMINISTRATIVE_REGISTRATION_SUCCESS',
  GET_ADMINISTRATIVE_REGISTRATION_ERROR:
    'GET_ADMINISTRATIVE_REGISTRATION_ERROR',

  APPROVE_ADMINISTRATIVE_REGISTRATION_REQUEST:
    'APPROVE_ADMINISTRATIVE_REGISTRATION_REQUEST',
  APPROVE_ADMINISTRATIVE_REGISTRATION_SUCCESS:
    'APPROVE_ADMINISTRATIVE_REGISTRATION_SUCCESS',
  APPROVE_ADMINISTRATIVE_REGISTRATION_ERROR:
    'APPROVE_ADMINISTRATIVE_REGISTRATION_ERROR',

  REVIEW_ADMINISTRATIVE_REGISTRATION_REQUEST:
    'REVIEW_ADMINISTRATIVE_REGISTRATION_REQUEST',
  REVIEW_ADMINISTRATIVE_REGISTRATION_SUCCESS:
    'REVIEW_ADMINISTRATIVE_REGISTRATION_SUCCESS',
  REVIEW_ADMINISTRATIVE_REGISTRATION_ERROR:
    'REVIEW_ADMINISTRATIVE_REGISTRATION_ERROR',

  GET_SEMESTER_EXAM_RECORDS_REQUEST: 'GET_SEMESTER_EXAM_RECORDS_REQUEST',
  GET_SEMESTER_EXAM_RECORDS_SUCCESS: 'GET_SEMESTER_EXAM_RECORDS_SUCCESS',
  GET_SEMESTER_EXAM_RECORDS_ERROR: 'GET_SEMESTER_EXAM_RECORDS_ERROR',

  ADD_SINGLE_STUDENT_TO_EXAM_TRACKER_REQUEST:
    'ADD_SINGLE_STUDENT_TO_EXAM_TRACKER_REQUEST',
  ADD_SINGLE_STUDENT_TO_EXAM_TRACKER_SUCCESS:
    'ADD_SINGLE_STUDENT_TO_EXAM_TRACKER_SUCCESS',
  ADD_SINGLE_STUDENT_TO_EXAM_TRACKER_ERROR:
    'ADD_SINGLE_STUDENT_TO_EXAM_TRACKER_ERROR',

  UPDATE_STUDENT_CONTEXT_ON_EXAM_TRACKER_REQUEST:
    'UPDATE_STUDENT_CONTEXT_ON_EXAM_TRACKER_REQUEST',
  UPDATE_STUDENT_CONTEXT_ON_EXAM_TRACKER_SUCCESS:
    'UPDATE_STUDENT_CONTEXT_ON_EXAM_TRACKER_SUCCESS',
  UPDATE_STUDENT_CONTEXT_ON_EXAM_TRACKER_ERROR:
    'UPDATE_STUDENT_CONTEXT_ON_EXAM_TRACKER_ERROR',

  DOWNLOAD_EXAM_RECORDS_TEMPLATE_REQUEST:
    'DOWNLOAD_EXAM_RECORDS_TEMPLATE_REQUEST',
  DOWNLOAD_EXAM_RECORDS_TEMPLATE_SUCCESS:
    'DOWNLOAD_EXAM_RECORDS_TEMPLATE_SUCCESS',
  DOWNLOAD_EXAM_RECORDS_TEMPLATE_ERROR: 'DOWNLOAD_EXAM_RECORDS_TEMPLATE_ERROR',

  UPLOAD_EXAM_RECORDS_TEMPLATE_REQUEST: 'UPLOAD_EXAM_RECORDS_TEMPLATE_REQUEST',
  UPLOAD_EXAM_RECORDS_TEMPLATE_SUCCESS: 'UPLOAD_EXAM_RECORDS_TEMPLATE_SUCCESS',
  UPLOAD_EXAM_RECORDS_TEMPLATE_ERROR: 'UPLOAD_EXAM_RECORDS_TEMPLATE_ERROR',

  SET_SELECTED_REGISTRATION_RECORD: 'SET_SELECTED_REGISTRATION_RECORD',

  SET_SELECTED_ADMINISTRATIVE_REGISTRATION_RECORD:
    'SET_SELECTED_ADMINISTRATIVE_REGISTRATION_RECORD',

  setSelectedAdministrativeRegRecord: (payload) => ({
    type: studentRegistration.SET_SELECTED_ADMINISTRATIVE_REGISTRATION_RECORD,
    payload,
  }),

  getAdministrativeRegistration: (url) => ({
    type: studentRegistration.GET_ADMINISTRATIVE_REGISTRATION_REQUEST,
    url,
  }),

  approveAdministrativeRegistration: (payload) => ({
    type: studentRegistration.APPROVE_ADMINISTRATIVE_REGISTRATION_REQUEST,
    payload,
  }),

  reviewAdministrativeRegistration: (data) => ({
    type: studentRegistration.REVIEW_ADMINISTRATIVE_REGISTRATION_REQUEST,
    data,
  }),
  registerStudent: (data, studentId, studentProgrammeId, status) => ({
    type: studentRegistration.REGISTER_STUDENT_REQUEST,
    data,
    studentId,
    studentProgrammeId,
    status,
  }),

  registerStudentFully: (
    data,
    registrationId,
    studentId,
    studentProgrammeId
  ) => ({
    type: studentRegistration.REGISTER_STUDENT_FULLY_REQUEST,
    data,
    registrationId,
    studentId,
    studentProgrammeId,
  }),

  updateStudentRegistration: (
    data,
    studentId,
    studentProgrammeId,
    registrationId
  ) => ({
    type: studentRegistration.UPDATE_STUDENT_REGISTRATION_REQUEST,
    data,
    studentId,
    studentProgrammeId,
    registrationId,
  }),

  deRegisterStudent: (data, studentId, studentProgrammeId) => ({
    type: studentRegistration.DE_REGISTER_STUDENT_REQUEST,
    data,
    studentId,
    studentProgrammeId,
  }),

  studentLateRegistration: (data, studentId, studentProgrammeId) => ({
    type: studentRegistration.ADD_LATE_REGISTRATION_REQUEST,
    data,
    studentId,
    studentProgrammeId,
  }),

  getRegistrationHistory: (studentId, studentProgrammeId) => ({
    type: studentRegistration.GET_REGISTRATION_HISTORY_REQUEST,
    studentId,
    studentProgrammeId,
  }),

  getPreviousRegistrationHistory: (studentNo) => ({
    type: studentRegistration.GET_PREVIOUS_REGISTRATION_REQUEST,
    studentNo,
  }),

  getMigratedEnrollmentBalances: (programmeId) => ({
    type: studentRegistration.GET_MIGRATED_ENROLLMENT_BALANCES_REQUEST,
    programmeId,
  }),

  billPreviousBalances: (data) => ({
    type: studentRegistration.BILL_PREVIOUS_BALANCES_REQUEST,
    data,
  }),
  addStudentToExamTracker: (data) => ({
    type: studentRegistration.ADD_SINGLE_STUDENT_TO_EXAM_TRACKER_REQUEST,
    data,
  }),
  updateStudentExamTrackerContext: (data, context) => ({
    type: studentRegistration.UPDATE_STUDENT_CONTEXT_ON_EXAM_TRACKER_REQUEST,
    data,
    context,
  }),
  getStudentSemesterExamRecords: (context) => ({
    type: studentRegistration.GET_SEMESTER_EXAM_RECORDS_REQUEST,
    context,
  }),
  downloadStudentSemesterExamTemplate: () => ({
    type: studentRegistration.DOWNLOAD_EXAM_RECORDS_TEMPLATE_REQUEST,
  }),
  uploadStudentSemesterExamTemplate: (context, data) => ({
    type: studentRegistration.UPLOAD_EXAM_RECORDS_TEMPLATE_REQUEST,
    context,
    data,
  }),

  setRegistrationRecord: (record) => ({
    type: studentRegistration.SET_SELECTED_REGISTRATION_RECORD,
    record,
  }),
};
export default studentRegistration;
