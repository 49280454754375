import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import {
  semesterEventActions,
  studentRegistrationActions,
} from '../../actions';

function* registerStudent(actions) {
  const url = actions.status ? 'register-student-late' : 'register';
  try {
    const { studentProgrammeId, studentId } = actions;
    const response = yield axios({
      url: `/registration/student-registration/${url}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentRegistrationActions.REGISTER_STUDENT_SUCCESS,
      data: response,
    });
    yield put({
      type: studentRegistrationActions.GET_REGISTRATION_HISTORY_REQUEST,
      studentId,
      studentProgrammeId,
    });
    yield put({
      type: semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST,
      studentId,
      studentProgrammeId,
    });
    yield put({
      type: 'SHOW_REGISTRATION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.REGISTER_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* registerStudentFully(actions) {
  try {
    const { registrationId, studentId, studentProgrammeId, data } = actions;
    const response = yield axios({
      url: `/registration/student-registration/register-fully/${registrationId}`,
      method: 'PUT',
      data,
    });
    yield put({
      type: studentRegistrationActions.REGISTER_STUDENT_FULLY_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_REGISTER_FULLY_MODAL',
      payload: {
        show: false,
        studentRegistration: {},
      },
    });
    yield put({
      type: studentRegistrationActions.GET_REGISTRATION_HISTORY_REQUEST,
      studentId,
      studentProgrammeId,
    });
    yield put({
      type: semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST,
      studentId,
      studentProgrammeId,
    });
    yield put({
      type: 'SHOW_REGISTRATION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.REGISTER_STUDENT_FULLY_ERROR,
      error: error.data,
    });
  }
}

function* updateStudentRegistration(actions) {
  try {
    const response = yield axios({
      url: `/registration/student-registration/update-course-units/${actions.registrationId}`,
      method: 'POST',
      data: { course_units: actions.data },
    });
    yield put({
      type: studentRegistrationActions.UPDATE_STUDENT_REGISTRATION_SUCCESS,
      data: response,
    });
    yield put({
      type: studentRegistrationActions.GET_REGISTRATION_HISTORY_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: 'SHOW_REGISTRATION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.UPDATE_STUDENT_REGISTRATION_ERROR,
      error: error.data,
    });
  }
}

function* deRegisterStudent(actions) {
  try {
    const response = yield axios({
      url: `/registration/student-registration/de-register/${actions.studentId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentRegistrationActions.DE_REGISTER_STUDENT_SUCCESS,
      data: response,
    });
    yield put({
      type: studentRegistrationActions.GET_REGISTRATION_HISTORY_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: 'SHOW_DE_REGISTER_MODAL',
      payload: { show: false, studentRegistration: {} },
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.DE_REGISTER_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* studentLateRegistration(actions) {
  try {
    const response = yield axios({
      url: `/registration/student-registration/late-registration/${actions.studentId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentRegistrationActions.ADD_LATE_REGISTRATION_SUCCESS,
      data: response,
    });
    yield put({
      type: studentRegistrationActions.GET_REGISTRATION_HISTORY_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: 'SHOW_REGISTRATION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.ADD_LATE_REGISTRATION_ERROR,
      error: error.data,
    });
  }
}

function* getRegistrationHistory(actions) {
  try {
    const response = yield axios({
      url: `/registration/student-registration/history/${actions.studentProgrammeId}`,
      method: 'GET',
    });
    yield put({
      type: studentRegistrationActions.GET_REGISTRATION_HISTORY_SUCCESS,
      data: response.data,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.GET_REGISTRATION_HISTORY_ERROR,
      error: error.data,
    });
  }
}

function* getPreviousRegistrationHistory(actions) {
  try {
    const response = yield axios({
      url: `/registration/migrated-enrollments`,
      method: 'GET',
      params: { student_number: actions.studentNo },
    });
    yield put({
      type: studentRegistrationActions.GET_PREVIOUS_REGISTRATION_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.GET_PREVIOUS_REGISTRATION_ERROR,
      error: error.data,
    });
  }
}

function* getMigratedEnrollmentBalances(actions) {
  try {
    const response = yield axios({
      url: `/registration/migrated-enrollments/enrollment-balances`,
      method: 'GET',
      params: { programme: actions.programmeId },
    });
    yield put({
      type: studentRegistrationActions.GET_MIGRATED_ENROLLMENT_BALANCES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.GET_MIGRATED_ENROLLMENT_BALANCES_ERROR,
      error: error.data,
    });
  }
}

function* getAdministrativeRegistrationRecord(actions) {
  try {
    const response = yield axios({
      url: `/registration/administrative-registration/${actions?.url}-administrative-registration-requests`,
      method: 'GET',
    });

    yield put({
      type: studentRegistrationActions.GET_ADMINISTRATIVE_REGISTRATION_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.GET_ADMINISTRATIVE_REGISTRATION_ERROR,
      error: error.data,
    });
  }
}

function* reviewAdministrativeRegistrationRecord(actions) {
  const { data } = actions;
  try {
    const response = yield axios({
      url: `/registration/administrative-registration/review-administrative-registration-requests`,
      method: 'PUT',
      data,
    });

    yield put({
      type: studentRegistrationActions.REVIEW_ADMINISTRATIVE_REGISTRATION_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
    yield put({
      type: studentRegistrationActions.GET_ADMINISTRATIVE_REGISTRATION_REQUEST,
      url: 'all',
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.REVIEW_ADMINISTRATIVE_REGISTRATION_ERROR,
      error: error.data,
    });
  }
}

function* approveAdministrativeRegistrationRecord(actions) {
  const { payload } = actions;
  try {
    const response = yield axios({
      url: `/registration/administrative-registration/approve-administrative-registration-requests`,
      method: 'PUT',
      data: payload,
    });

    yield put({
      type: studentRegistrationActions.APPROVE_ADMINISTRATIVE_REGISTRATION_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
    yield put({
      type: studentRegistrationActions.GET_ADMINISTRATIVE_REGISTRATION_REQUEST,
      url: 'all',
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.APPROVE_ADMINISTRATIVE_REGISTRATION_ERROR,
      error: error.data,
    });
  }
}

function* billPreviousBalances(actions) {
  try {
    const response = yield axios({
      url: `/registration/enrollments/bill-previous-balances`,
      method: 'POST',
      data: actions.data.students,
    });
    yield put({
      type: studentRegistrationActions.BILL_PREVIOUS_BALANCES_SUCCESS,
      data: response.data,
    });
    yield put({
      type: studentRegistrationActions.GET_MIGRATED_ENROLLMENT_BALANCES_REQUEST,
      programmeId: actions.data.programme,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.BILL_PREVIOUS_BALANCES_ERROR,
      error: error.data,
    });
  }
}

function* getSemesterExaminationRecords(actions) {
  try {
    const response = yield axios({
      url: `/registration/exam-tracker`,
      method: 'GET',
      params: actions.context,
    });

    yield put({
      type: studentRegistrationActions.GET_SEMESTER_EXAM_RECORDS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.GET_SEMESTER_EXAM_RECORDS_ERROR,
      error: error.data,
    });
  }
}

function* addStudentToExaminationRecords(actions) {
  try {
    const response = yield axios({
      url: `/registration/exam-tracker/add-single-student`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentRegistrationActions.ADD_SINGLE_STUDENT_TO_EXAM_TRACKER_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.ADD_SINGLE_STUDENT_TO_EXAM_TRACKER_ERROR,
      error: error.data,
    });
  }
}

function* updateStudentContextOnExaminationRecord(actions) {
  try {
    const response = yield axios({
      url: `/registration/exam-tracker/update-exam-tracker`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentRegistrationActions.UPDATE_STUDENT_CONTEXT_ON_EXAM_TRACKER_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_EDIT_MODAL',
      payload: false,
    });
    yield put({
      type: studentRegistrationActions.GET_SEMESTER_EXAM_RECORDS_REQUEST,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.UPDATE_STUDENT_CONTEXT_ON_EXAM_TRACKER_ERROR,
      error: error.data,
    });
  }
}

function* downloadStudentExamTemplate() {
  try {
    yield axios({
      url: `/registration/exam-tracker/download-template`,
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-EXAM-TRACKER-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: studentRegistrationActions.DOWNLOAD_EXAM_RECORDS_TEMPLATE_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.DOWNLOAD_EXAM_RECORDS_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* uploadStudentExamTemplate(actions) {
  try {
    const response = yield axios({
      url: '/registration/exam-tracker/upload-template',
      method: 'POST',
      data: actions.data,
      params: actions.context,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: studentRegistrationActions.UPLOAD_EXAM_RECORDS_TEMPLATE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.UPLOAD_EXAM_RECORDS_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAdministrativeReg() {
  yield takeLatest(
    studentRegistrationActions.GET_ADMINISTRATIVE_REGISTRATION_REQUEST,
    getAdministrativeRegistrationRecord
  );
}

function* watchReviewAdministrativeRegRecord() {
  yield takeLatest(
    studentRegistrationActions.REVIEW_ADMINISTRATIVE_REGISTRATION_REQUEST,
    reviewAdministrativeRegistrationRecord
  );
}

function* watchApproveAdministrativeRegRecord() {
  yield takeLatest(
    studentRegistrationActions.APPROVE_ADMINISTRATIVE_REGISTRATION_REQUEST,
    approveAdministrativeRegistrationRecord
  );
}

function* watchRegisterStudent() {
  yield takeLatest(
    studentRegistrationActions.REGISTER_STUDENT_REQUEST,
    registerStudent
  );
}

function* watchRegisterStudentFully() {
  yield takeLatest(
    studentRegistrationActions.REGISTER_STUDENT_FULLY_REQUEST,
    registerStudentFully
  );
}

function* watchUpdateStudentRegistration() {
  yield takeLatest(
    studentRegistrationActions.UPDATE_STUDENT_REGISTRATION_REQUEST,
    updateStudentRegistration
  );
}
function* watchDeRegisterStudent() {
  yield takeLatest(
    studentRegistrationActions.DE_REGISTER_STUDENT_REQUEST,
    deRegisterStudent
  );
}
function* watchLateRegistrationStudent() {
  yield takeLatest(
    studentRegistrationActions.ADD_LATE_REGISTRATION_REQUEST,
    studentLateRegistration
  );
}
function* watchRegistrationHistory() {
  yield takeLatest(
    studentRegistrationActions.GET_REGISTRATION_HISTORY_REQUEST,
    getRegistrationHistory
  );
}
function* watchPreviousRegistrationHistory() {
  yield takeLatest(
    studentRegistrationActions.GET_PREVIOUS_REGISTRATION_REQUEST,
    getPreviousRegistrationHistory
  );
}
function* watchGetMigratedEnrollmentBalances() {
  yield takeLatest(
    studentRegistrationActions.GET_MIGRATED_ENROLLMENT_BALANCES_REQUEST,
    getMigratedEnrollmentBalances
  );
}
function* watchBillPreviousBalances() {
  yield takeLatest(
    studentRegistrationActions.BILL_PREVIOUS_BALANCES_REQUEST,
    billPreviousBalances
  );
}
function* watchGetSemesterExaminationRecords() {
  yield takeLatest(
    studentRegistrationActions.GET_SEMESTER_EXAM_RECORDS_REQUEST,
    getSemesterExaminationRecords
  );
}

function* watchAddStudentToExaminationRecords() {
  yield takeLatest(
    studentRegistrationActions.ADD_SINGLE_STUDENT_TO_EXAM_TRACKER_REQUEST,
    addStudentToExaminationRecords
  );
}
function* watchUpdateStudentContextOnExaminationRecord() {
  yield takeLatest(
    studentRegistrationActions.UPDATE_STUDENT_CONTEXT_ON_EXAM_TRACKER_REQUEST,
    updateStudentContextOnExaminationRecord
  );
}
function* watchDownloadStudentExamTemplate() {
  yield takeLatest(
    studentRegistrationActions.DOWNLOAD_EXAM_RECORDS_TEMPLATE_REQUEST,
    downloadStudentExamTemplate
  );
}
function* watchUploadStudentExamTemplate() {
  yield takeLatest(
    studentRegistrationActions.UPLOAD_EXAM_RECORDS_TEMPLATE_REQUEST,
    uploadStudentExamTemplate
  );
}

export default [
  fork(watchRegisterStudent),
  fork(watchDeRegisterStudent),
  fork(watchRegistrationHistory),
  fork(watchRegisterStudentFully),
  fork(watchBillPreviousBalances),
  fork(watchLateRegistrationStudent),
  fork(watchUpdateStudentRegistration),
  fork(watchPreviousRegistrationHistory),
  fork(watchGetMigratedEnrollmentBalances),
  fork(watchGetAdministrativeReg),
  fork(watchGetSemesterExaminationRecords),
  fork(watchApproveAdministrativeRegRecord),
  fork(watchReviewAdministrativeRegRecord),
  fork(watchDownloadStudentExamTemplate),
  fork(watchUploadStudentExamTemplate),
  fork(watchUpdateStudentContextOnExaminationRecord),
  fork(watchAddStudentToExaminationRecords),
];
