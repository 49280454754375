import axios from 'axios';
import FileSaver from 'file-saver';
import { toUpper } from 'lodash';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { sponsorActions } from '../../actions';

function* getSponsors() {
  try {
    const response = yield axios({
      url: `/universal-payments/sponsors`,
      method: 'GET',
    });
    yield put({
      type: sponsorActions.GET_SPONSORS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: sponsorActions.GET_SPONSORS_ERROR,
      error: error.data,
    });
  }
}

function* createSponsor(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/sponsors`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: sponsorActions.CREATE_SPONSOR_SUCCESS,
      data: response.data,
    });
    yield put({ type: sponsorActions.SHOW_SPONSOR_MODAL, payload: false });
    yield put({
      type: sponsorActions.GET_SPONSORS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: sponsorActions.CREATE_SPONSOR_ERROR,
      error: error.data,
    });
  }
}

function* generateSponsorInvoice(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/sponsors/generate-invoice`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: sponsorActions.GENERATE_SPONSOR_INVOICE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: sponsorActions.GET_SPONSOR_INVOICES_REQUEST,
      sponsorId: actions.data.sponsor_id,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
    yield put({ type: 'OPEN_MODAL', payload: true });
  } catch (error) {
    yield put({
      type: sponsorActions.GENERATE_SPONSOR_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* regeneratePRN(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/sponsors/generate-new-prn/${actions.invoiceId}`,
      method: 'POST',
    });
    yield put({
      type: sponsorActions.RE_GENERATE_SPONSOR_PRN_SUCCESS,
      data: response.data,
    });
    yield put({
      type: sponsorActions.GET_SPONSOR_INVOICES_REQUEST,
      sponsorId: actions.sponsorId,
    });
  } catch (error) {
    yield put({
      type: sponsorActions.RE_GENERATE_SPONSOR_PRN_ERROR,
      error: error.data,
    });
  }
}

function* getSponsorInvoices(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/sponsors/all-sponsor-invoices/${actions.sponsorId}`,
      method: 'GET',
    });
    yield put({
      type: sponsorActions.GET_SPONSOR_INVOICES_SUCCESS,
      data: response.data,
      sponsorId: actions.sponsorId,
    });
  } catch (error) {
    yield put({
      type: sponsorActions.GET_SPONSOR_INVOICES_ERROR,
      error: error.data,
    });
  }
}

function* getSponsorPayments(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/sponsors/sponsor-account-balance/${actions.sponsorId}`,
      method: 'GET',
    });
    yield put({
      type: sponsorActions.GET_SPONSOR_PAYMENTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: sponsorActions.GET_SPONSOR_PAYMENTS_ERROR,
      error: error.data,
    });
  }
}

function* getSponsorAllocations(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/sponsors/transaction-allocations/${actions.allocationId}`,
      method: 'GET',
    });
    yield put({
      type: sponsorActions.GET_SPONSOR_ALLOCATIONS_SUCCESS,
      data: response.data,
      allocationId: actions.allocationId,
    });
  } catch (error) {
    yield put({
      type: sponsorActions.GET_SPONSOR_ALLOCATIONS_ERROR,
      error: error.data,
    });
  }
}

function* deAllocateSponsorPayments(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/sponsors/de-allocate-from-sponsored-students`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: sponsorActions.DEALLOCATE_SPONSOR_PAYMENTS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: sponsorActions.GET_SPONSOR_ALLOCATIONS_REQUEST,
      allocationId: actions.allocationId,
    });
  } catch (error) {
    yield put({
      type: sponsorActions.DEALLOCATE_SPONSOR_PAYMENTS_ERROR,
      error: error.data,
    });
  }
}

function* getSponsorStudents(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/sponsors/fetch-sponsor-students/${actions.sponsorId}`,
      method: 'GET',
    });
    const data = response?.data.map((item) => {
      const { other_names: other } = item.studentProgramme.student;
      const { surname } = item.studentProgramme.student;
      const { student_number: stdNo } = item.studentProgramme;
      const { registration_number: regNo } = item.studentProgramme;

      return {
        ...item,
        registration_number: regNo,
        student_number: stdNo,
        other_names: other,
        surname,
      };
    });
    yield put({
      type: sponsorActions.GET_SPONSOR_STUDENTS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: sponsorActions.GET_SPONSOR_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* updateSponsor(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/sponsors/${actions.sponsorId}`,
      method: 'PUT',
      data: actions.payload,
    });
    yield put({
      type: sponsorActions.UPDATE_SPONSOR_SUCCESS,
      data: response.data,
    });
    yield put({
      type: sponsorActions.SET_SPONSOR_CURRENT_MENU,
      payload: 'details',
    });
    yield put({
      type: sponsorActions.GET_SPONSORS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: sponsorActions.UPDATE_SPONSOR_ERROR,
      error: error.data,
    });
  }
}

function* deleteSponsor(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/sponsors/${actions.sponsorId}`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: sponsorActions.DELETE_SPONSOR_SUCCESS,
      data: response.data,
    });
    yield put({ type: 'SHOW_DELETE_MODAL', payload: false });
    yield put({
      type: sponsorActions.GET_SPONSORS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: sponsorActions.DELETE_SPONSOR_ERROR,
      error: error.data,
    });
  }
}

function* getSponsorByContext(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/sponsors-by-context`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: sponsorActions.GET_SPONSOR_BY_CONTEXT_SUCCESS,
      data: response.data,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: sponsorActions.GET_SPONSOR_BY_CONTEXT_ERROR,
      error: error.data,
    });
  }
}

function* downloadPaymentsTemplate(actions) {
  try {
    yield axios({
      url: `/universal-payments/sponsors/download-sponsor-${actions.param}-template`,
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-SPONSOR-${toUpper(
          actions.param
        )}-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: sponsorActions.DOWNLOAD_SPONSOR_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: sponsorActions.DOWNLOAD_SPONSOR_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* uploadPaymentsTemplate(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/sponsors/upload-sponsor-${actions.param}-template`,
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: sponsorActions.UPLOAD_SPONSOR_TEMPLATE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: sponsorActions.UPLOAD_SPONSOR_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* downloadSponsorTransactionsTemplate() {
  try {
    yield axios({
      url: `/universal-payments/sponsors/download-sponsor-transactions-template`,
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-SPONSOR-TRANSACTIONS-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: sponsorActions.DOWNLOAD_SPONSOR_TRANSACTIONS_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: sponsorActions.DOWNLOAD_SPONSOR_TRANSACTIONS_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* uploadSponsorTransactionsTemplate(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/sponsors/upload-sponsor-transactions-template`,
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: sponsorActions.UPLOAD_SPONSOR_TRANSACTIONS_TEMPLATE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: sponsorActions.UPLOAD_SPONSOR_TRANSACTIONS_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* allocateSponsorFunds(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/sponsors/allocate-to-sponsored-students/${actions.transactionId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: sponsorActions.ALLOCATE_SPONSOR_FUNDS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: sponsorActions.GET_SPONSOR_PAYMENTS_REQUEST,
      sponsorId: actions.sponsorId,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: sponsorActions.ALLOCATE_SPONSOR_FUNDS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSponsors() {
  yield takeLatest(sponsorActions.GET_SPONSORS_REQUEST, getSponsors);
}

function* watchCreateSponsor() {
  yield takeLatest(sponsorActions.CREATE_SPONSOR_REQUEST, createSponsor);
}

function* watchGenerateSponsorInvoice() {
  yield takeLatest(
    sponsorActions.GENERATE_SPONSOR_INVOICE_REQUEST,
    generateSponsorInvoice
  );
}

function* watchRegeneratePRN() {
  yield takeLatest(
    sponsorActions.RE_GENERATE_SPONSOR_PRN_REQUEST,
    regeneratePRN
  );
}

function* watchGetSponsorInvoices() {
  yield takeLatest(
    sponsorActions.GET_SPONSOR_INVOICES_REQUEST,
    getSponsorInvoices
  );
}

function* watchGetSponsorPayments() {
  yield takeLatest(
    sponsorActions.GET_SPONSOR_PAYMENTS_REQUEST,
    getSponsorPayments
  );
}

function* watchGetSponsorAllocations() {
  yield takeLatest(
    sponsorActions.GET_SPONSOR_ALLOCATIONS_REQUEST,
    getSponsorAllocations
  );
}

function* watchDeAllocateSponsorPayments() {
  yield takeLatest(
    sponsorActions.DEALLOCATE_SPONSOR_PAYMENTS_REQUEST,
    deAllocateSponsorPayments
  );
}

function* watchGetSponsorStudents() {
  yield takeLatest(
    sponsorActions.GET_SPONSOR_STUDENTS_REQUEST,
    getSponsorStudents
  );
}

function* watchUpdateSponsor() {
  yield takeLatest(sponsorActions.UPDATE_SPONSOR_REQUEST, updateSponsor);
}

function* watchDeleteSponsor() {
  yield takeLatest(sponsorActions.DELETE_SPONSOR_REQUEST, deleteSponsor);
}

function* watchGetSponsorByContext() {
  yield takeLatest(
    sponsorActions.GET_SPONSOR_BY_CONTEXT_REQUEST,
    getSponsorByContext
  );
}

function* watchDownloadPaymentsTemplate() {
  yield takeLatest(
    sponsorActions.DOWNLOAD_SPONSOR_TEMPLATE_REQUEST,
    downloadPaymentsTemplate
  );
}

function* watchUploadPaymentsTemplate() {
  yield takeLatest(
    sponsorActions.UPLOAD_SPONSOR_TEMPLATE_REQUEST,
    uploadPaymentsTemplate
  );
}

function* watchUploadSponsorTransactionsTemplate() {
  yield takeLatest(
    sponsorActions.UPLOAD_SPONSOR_TRANSACTIONS_TEMPLATE_REQUEST,
    uploadSponsorTransactionsTemplate
  );
}

function* watchDownloadSponsorTransactionsTemplate() {
  yield takeLatest(
    sponsorActions.DOWNLOAD_SPONSOR_TRANSACTIONS_TEMPLATE_REQUEST,
    downloadSponsorTransactionsTemplate
  );
}

function* watchAllocateSponsorFunds() {
  yield takeLatest(
    sponsorActions.ALLOCATE_SPONSOR_FUNDS_REQUEST,
    allocateSponsorFunds
  );
}

export default [
  fork(watchGetSponsors),
  fork(watchCreateSponsor),
  fork(watchUpdateSponsor),
  fork(watchDeleteSponsor),
  fork(watchRegeneratePRN),
  fork(watchGetSponsorPayments),
  fork(watchGetSponsorAllocations),
  fork(watchDeAllocateSponsorPayments),
  fork(watchGetSponsorStudents),
  fork(watchAllocateSponsorFunds),
  fork(watchGetSponsorInvoices),
  fork(watchGetSponsorByContext),
  fork(watchGenerateSponsorInvoice),
  fork(watchUploadPaymentsTemplate),
  fork(watchDownloadPaymentsTemplate),
  fork(watchUploadSponsorTransactionsTemplate),
  fork(watchDownloadSponsorTransactionsTemplate),
];
