const institutionPolicy = {
  academicFeesPolicy: {
    academicFeesPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    academicFeesPolicy: {},
    policyToEdit: {},
    selectedPolicy: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
  },
  applicationFeesPolicy: {
    applicationFeesPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    applicationFeesPolicy: {},
    policyToEdit: {},
    selectedPolicy: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,

    updateApplicationPolicyError: {},
    updateApplicationPolicyResponse: {},
    updatingApplicationPolicy: false,
  },
  admissionFeesPolicy: {
    admissionFeesPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    admissionFeesPolicy: {},
    policyToEdit: {},
    selectedPolicy: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,

    updateAdmissionPolicyError: {},
    updateAdmissionPolicyResponse: {},
    updatingAdmissionPolicy: false,

    deleteAdmissionPolicyError: {},
    deleteAdmissionPolicyResponse: {},
    deletingAdmissionPolicy: false,
  },
  registrationPolicy: {
    registrationPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    registrationPolicy: {},
    policyToEdit: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
  },
  otherFeesPolicy: {
    otherFeesPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    otherFeesPolicy: {},
    policyToEdit: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
  },
  documentPolicy: {
    creatingPolicy: false,
    createPolicyError: {},
    createdPolicyResponse: {},

    updatingPolicy: false,
    updatePolicyError: {},
    updatedPolicyResponse: {},

    fetchingDocumentPolicies: false,
    fetchDocumentPolicyError: {},
    documentPolicies: [],

    deletePolicyResponse: {},
    deletePolicyError: {},
    deletingPolicy: false,

    fetchRegHistoryPolicyError: {},
    regHistoryPolicies: [],
    fetchingRegHistoryPolicies: false,

    updateRegHistoryResponse: {},
    updateRegHistoryPolicyError: {},
    updatingRegHistoryPolicy: false,

    createdRegHistoryPolicyResponse: {},
    createRegHistoryPolicyError: {},
    creatingRegHistoryPolicy: false,

    deleteRegHistoryError: {},
    deleteRegHistoryResponse: {},
    deletingRegHistory: false,
  },
  surchargePolicy: {
    surchargePolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    enrollmentPolicy: {},
    policyToEdit: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
    deletingElements: false,
    deleteElementError: {},
    deleteElementSuccess: {},
  },
  retakerPolicy: {
    retakerPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    retakerPolicy: {},
    policyToEdit: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
    deletingElements: false,
    deleteElementError: {},
    deleteElementSuccess: {},
  },
  extensionPolicy: {
    extensionPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    extensionPolicy: {},
    policyToEdit: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
    deletingElements: false,
    deleteElementError: {},
    deleteElementSuccess: {},
  },
  tuitionFeesPolicy: {
    tuitionFeesPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    tuitionFeesPolicy: {},
    policyToEdit: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
  },
  studyLevelPassMarkPolicy: {
    loading: false,
    studyLevelPolicies: [],
    loadError: {},

    creating: false,
    createResponse: {},
    createError: {},

    deleting: false,
    deleteResponse: {},
    deleteError: {},

    updating: false,
    updateResponse: {},
    updateError: {},

    policyToEdit: {},
  },
  courseResittingPolicy: {
    loading: false,
    allPolicies: [],
    loadError: {},

    creating: false,
    createResponse: {},
    createError: {},

    deleting: false,
    deleteResponse: {},
    deleteError: {},

    updating: false,
    updateResponse: {},
    updateError: {},

    policyToEdit: {},
  },
  resultsCategoryPolicy: {
    loading: false,
    resultsCategoryPolicies: [],
    degreeClassPolicies: [],
    loadError: {},

    creating: false,
    createResponse: {},
    createError: {},

    deleting: false,
    deleteResponse: {},
    deleteError: {},

    updating: false,
    updateResponse: {},
    updateError: {},

    policyToEdit: {},
    selectedLevel: null,
  },
  servicesPolicy: {
    loading: false,
    servicePolicies: [],
    loadError: {},

    creatingServicePolicy: false,
    createResponse: {},
    createError: {},

    deletingServicePolicy: false,
    deleteResponse: {},
    deleteError: {},

    updatingServicePolicy: false,
    updateResponse: {},
    updateError: {},

    policyToEdit: {},
  },
  feesBillingPolicy: {
    feesBillingPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    feesBillingPolicy: {},
    policyToEdit: {},
    createError: {},

    moduleFeesPolicy: {},
    moduleFeesPolicies: [],

    activating: false,
    activateResponse: {},
    activateError: {},

    approving: false,
    approveResponse: {},
    approveError: {},

    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
    addingContext: false,
  },
  resultApprovalPolicy: {
    activeTab: 'view',

    deleteError: {},
    deleting: false,

    deleteModal: {},

    updatingResultApprovalPolicy: false,
    createResultApprovalPolicyError: {},

    gettingResultApprovalPolicy: false,
    resultApprovalPolicy: [],
    resultApprovalPolicyError: {},

    policyToEdit: {},
  },
  concededPassPolicy: {
    loading: false,
    concededPassPolicies: [],
    loadError: {},

    creatingPolicy: false,
    createResponse: {},
    createError: {},

    deletingPolicy: false,
    deleteResponse: {},
    deleteError: {},

    updatingPolicy: false,
    updateResponse: {},
    updateError: {},

    policyToEdit: {},
    showModal: false,
  },
  resultsBlockingPolicy: {
    activeTab: 'view',

    gettingResultBlockingPolicy: false,
    resultBlockingPolicies: [],
    resultBlockingPolicyError: {},

    gettingResultRegistrationPolicy: false,
    resultsRegistrationPolicies: {},
    resultRegistrationPoliciesError: {},

    gettingResultFeesBalancePolicy: false,
    resultsFeesBalancePolicies: {},
    resultFeesBalancePoliciesError: {},

    updatingPolicy: false,
    updatePolicySuccess: {},
    updatePolicyError: {},

    gettingPendingPolicies: false,
    pendingPolicies: [],
    pendingPoliciesError: {},

    approvingPendingPolicy: false,
    approvePendingPolicySuccess: [],
    approvePendingPolicyError: {},
  },
  timetablePolicy: {
    selectedTimetablePolicy: {},

    teachingLoadPolicies: [],
    getTeachingLoadPolicy: false,
    teachingLoadPolicyError: {},

    createTeachingLoadPolicy: false,
    createTeachingLoadPolicySuccess: {},
    createTeachingLoadPolicyError: {},

    deleteTeachingLoadPolicy: false,
    deleteTeachingLoadPolicySuccess: {},
    deleteTeachingLoadPolicyError: {},

    updateTeachingLoadPolicy: false,
    updateTeachingLoadPolicySuccess: {},
    updateTeachingLoadPolicyError: {},

    extraTeachingLoadPolicies: [],
    getExtraTeachingLoadPolicy: false,
    extraTeachingLoadPolicyError: {},

    createExtraTeachingLoadPolicy: false,
    createExtraTeachingLoadPolicyError: {},

    deleteExtraTeachingLoadPolicy: false,
    deleteExtraTeachingLoadPolicyError: {},

    updateExtraTeachingLoadPolicy: false,
    updateExtraTeachingLoadPolicyError: {},

    creatingFacultyExtraTeachingLoadPolicy: false,
    createFacultyExtraTeachingLoadPolicyError: {},
    createFacultyExtraTeachingLoadPolicySuccess: {},

    updatingFacultyExtraTeachingLoadPolicy: false,
    updateFacultyExtraTeachingLoadPolicyError: {},
    updateFacultyExtraTeachingLoadPolicySuccess: {},

    deletingFacultyExtraTeachingLoadPolicy: false,
    deleteFacultyExtraTeachingLoadPolicyError: {},
    deleteFacultyExtraTeachingLoadPolicySuccess: {},

    creatingDepartmentExtraTeachingLoadPolicy: false,
    createDepartmentExtraTeachingLoadPolicyError: {},
    createDepartmentExtraTeachingLoadPolicySuccess: {},

    updatingDepartmentExtraTeachingLoadPolicy: false,
    updateDepartmentExtraTeachingLoadPolicyError: {},
    updateDepartmentExtraTeachingLoadPolicySuccess: {},

    deletingDepartmentExtraTeachingLoadPolicy: false,
    deleteDepartmentExtraTeachingLoadPolicyError: {},
    deleteDepartmentExtraTeachingLoadPolicySuccess: {},

    creatingProgrammeExtraTeachingLoadPolicy: false,
    createProgrammeExtraTeachingLoadPolicyError: {},
    createProgrammeExtraTeachingLoadPolicySuccess: {},

    updatingProgrammeExtraTeachingLoadPolicy: false,
    updateProgrammeExtraTeachingLoadPolicyError: {},
    updateProgrammeExtraTeachingLoadPolicySuccess: {},

    deletingProgrammeExtraTeachingLoadPolicy: false,
    deleteProgrammeExtraTeachingLoadPolicyError: {},
    deleteProgrammeExtraTeachingLoadPolicySuccess: {},
  },
};

export default institutionPolicy;
