const timetablePolicyActions = {
  GET_TEACHING_LOAD_POLICY_REQUEST: 'GET_TEACHING_LOAD_POLICY_REQUEST',
  GET_TEACHING_LOAD_POLICY_SUCCESS: 'GET_TEACHING_LOAD_POLICY_SUCCESS',
  GET_TEACHING_LOAD_POLICY_ERROR: 'GET_TEACHING_LOAD_POLICY_ERROR',

  CREATE_TEACHING_LOAD_POLICY_REQUEST: 'CREATE_TEACHING_LOAD_POLICY_REQUEST',
  CREATE_TEACHING_LOAD_POLICY_SUCCESS: 'CREATE_TEACHING_LOAD_POLICY_SUCCESS',
  CREATE_TEACHING_LOAD_POLICY_ERROR: 'CREATE_TEACHING_LOAD_POLICY_ERROR',

  UPDATE_TEACHING_LOAD_POLICY_REQUEST: 'UPDATE_TEACHING_LOAD_POLICY_REQUEST',
  UPDATE_TEACHING_LOAD_POLICY_SUCCESS: 'UPDATE_TEACHING_LOAD_POLICY_SUCCESS',
  UPDATE_TEACHING_LOAD_POLICY_ERROR: 'UPDATE_TEACHING_LOAD_POLICY_ERROR',

  DELETE_TEACHING_LOAD_POLICY_REQUEST: 'DELETE_TEACHING_LOAD_POLICY_REQUEST',
  DELETE_TEACHING_LOAD_POLICY_SUCCESS: 'DELETE_TEACHING_LOAD_POLICY_SUCCESS',
  DELETE_TEACHING_LOAD_POLICY_ERROR: 'DELETE_TEACHING_LOAD_POLICY_ERROR',

  GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
    'GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST',
  GET_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
    'GET_EXTRA_TEACHING_LOAD_POLICY_SUCCESS',
  GET_EXTRA_TEACHING_LOAD_POLICY_ERROR: 'GET_EXTRA_TEACHING_LOAD_POLICY_ERROR',

  CREATE_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
    'CREATE_EXTRA_TEACHING_LOAD_POLICY_REQUEST',
  CREATE_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
    'CREATE_EXTRA_TEACHING_LOAD_POLICY_SUCCESS',
  CREATE_EXTRA_TEACHING_LOAD_POLICY_ERROR:
    'CREATE_EXTRA_TEACHING_LOAD_POLICY_ERROR',

  UPDATE_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
    'UPDATE_EXTRA_TEACHING_LOAD_POLICY_REQUEST',
  UPDATE_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
    'UPDATE_EXTRA_TEACHING_LOAD_POLICY_SUCCESS',
  UPDATE_EXTRA_TEACHING_LOAD_POLICY_ERROR:
    'UPDATE_EXTRA_TEACHING_LOAD_POLICY_ERROR',

  DELETE_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
    'DELETE_EXTRA_TEACHING_LOAD_POLICY_REQUEST',
  DELETE_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
    'DELETE_EXTRA_TEACHING_LOAD_POLICY_SUCCESS',
  DELETE_EXTRA_TEACHING_LOAD_POLICY_ERROR:
    'DELETE_EXTRA_TEACHING_LOAD_POLICY_ERROR',

  GET_FACULTY_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
    'GET_FACULTY_EXTRA_TEACHING_LOAD_POLICY_REQUEST',
  GET_FACULTY_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
    'GET_FACULTY_EXTRA_TEACHING_LOAD_POLICY_SUCCESS',
  GET_FACULTY_EXTRA_TEACHING_LOAD_POLICY_ERROR:
    'GET_FACULTY_EXTRA_TEACHING_LOAD_POLICY_ERROR',

  CREATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
    'CREATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_REQUEST',
  CREATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
    'CREATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_SUCCESS',
  CREATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_ERROR:
    'CREATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_ERROR',

  UPDATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
    'UPDATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_REQUEST',
  UPDATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
    'UPDATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_SUCCESS',
  UPDATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_ERROR:
    'UPDATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_ERROR',

  DELETE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
    'DELETE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_REQUEST',
  DELETE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
    'DELETE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_SUCCESS',
  DELETE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_ERROR:
    'DELETE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_ERROR',

  GET_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
    'GET_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_REQUEST',
  GET_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
    'GET_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_SUCCESS',
  GET_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_ERROR:
    'GET_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_ERROR',

  CREATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
    'CREATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_REQUEST',
  CREATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
    'CREATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_SUCCESS',
  CREATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_ERROR:
    'CREATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_ERROR',

  UPDATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
    'UPDATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_REQUEST',
  UPDATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
    'UPDATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_SUCCESS',
  UPDATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_ERROR:
    'UPDATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_ERROR',

  DELETE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
    'DELETE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_REQUEST',
  DELETE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
    'DELETE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_SUCCESS',
  DELETE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_ERROR:
    'DELETE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_ERROR',

  GET_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
    'GET_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_REQUEST',
  GET_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
    'GET_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_SUCCESS',
  GET_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_ERROR:
    'GET_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_ERROR',

  CREATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
    'CREATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_REQUEST',
  CREATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
    'CREATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_SUCCESS',
  CREATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_ERROR:
    'CREATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_ERROR',

  UPDATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
    'UPDATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_REQUEST',
  UPDATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
    'UPDATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_SUCCESS',
  UPDATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_ERROR:
    'UPDATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_ERROR',

  DELETE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
    'DELETE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_REQUEST',
  DELETE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
    'DELETE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_SUCCESS',
  DELETE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_ERROR:
    'DELETE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_ERROR',

  SET_SELECTED_TIMETABLE_POLICY: 'SET_SELECTED_TIMETABLE_POLICY',

  getTeachingLoadPolicies: (params) => ({
    type: timetablePolicyActions.GET_TEACHING_LOAD_POLICY_REQUEST,
    params,
  }),

  getExtraTeachingLoadPolicies: (params) => ({
    type: timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    params,
  }),

  createTeachingLoadPolicy: (data) => ({
    type: timetablePolicyActions.CREATE_TEACHING_LOAD_POLICY_REQUEST,
    data,
  }),

  createExtraTeachingLoadPolicy: (data) => ({
    type: timetablePolicyActions.CREATE_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    data,
  }),

  createFacultyExtraTeachingLoadPolicy: (data) => ({
    type: timetablePolicyActions.CREATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    data,
  }),

  createDepartmentExtraTeachingLoadPolicy: (data) => ({
    type: timetablePolicyActions.CREATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    data,
  }),

  createProgrammeExtraTeachingLoadPolicy: (data) => ({
    type: timetablePolicyActions.CREATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    data,
  }),

  updateTeachingLoadPolicy: (policyId, data) => ({
    type: timetablePolicyActions.UPDATE_TEACHING_LOAD_POLICY_REQUEST,
    data,
    policyId,
  }),

  updateExtraTeachingLoadPolicy: (policyId, data) => ({
    type: timetablePolicyActions.UPDATE_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    data,
    policyId,
  }),

  updateFacultyExtraTeachingLoadPolicy: (policyId, data) => ({
    type: timetablePolicyActions.UPDATE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    data,
    policyId,
  }),

  updateDepartmentExtraTeachingLoadPolicy: (policyId, data) => ({
    type: timetablePolicyActions.UPDATE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    data,
    policyId,
  }),

  updateProgrammeExtraTeachingLoadPolicy: (policyId, data) => ({
    type: timetablePolicyActions.UPDATE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    data,
    policyId,
  }),

  deleteTeachingLoadPolicy: (policyId) => ({
    type: timetablePolicyActions.DELETE_TEACHING_LOAD_POLICY_REQUEST,
    policyId,
  }),

  deleteExtraTeachingLoadPolicy: (policyId) => ({
    type: timetablePolicyActions.DELETE_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    policyId,
  }),

  deleteFacultyExtraTeachingLoadPolicy: (policyId) => ({
    type: timetablePolicyActions.DELETE_FACULTY_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    policyId,
  }),

  deleteDepartmentExtraTeachingLoadPolicy: (policyId) => ({
    type: timetablePolicyActions.DELETE_DEPARTMENT_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    policyId,
  }),

  deleteProgrammeExtraTeachingLoadPolicy: (policyId) => ({
    type: timetablePolicyActions.DELETE_PROGRAMME_EXTRA_TEACHING_LOAD_POLICY_REQUEST,
    policyId,
  }),

  setSelectedTimetablePolicy: (data) => ({
    type: timetablePolicyActions.SET_SELECTED_TIMETABLE_POLICY,
    data,
  }),
};

export default timetablePolicyActions;
